import axios from "axios";

const token = process.env.REACT_APP_MAIN_API_TOKEN;

const mainAPIClient = axios.create({
  baseURL:
    process.env.NODE_ENV === "production"
      ? process.env.REACT_APP_MAIN_API_URL
      : process.env.REACT_APP_MAIN_API_URL_LOCAL,
  headers: {
    "X-Request-Agent": "gema-web",
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  },
});

export async function registerOrAttendEvent(eventId, values, status, isMember) {
  try {
    if (isMember) {
      const response = await mainAPIClient.put(
        `${
          status === 0 ? "/event/member/registration" : "/event/member/attend"
        }`,
        {
          eventId,
          nationalId: values.nationalId,
        }
      );
      if (response.status === 200) {
        return response.data;
      }
    } else {
      const response = await mainAPIClient.put(
        `${
          status === 0
            ? "/event/associate/registration"
            : "/event/associate/attend"
        }`,
        {
          eventId,
          fullname: values.fullname,
          phone: values.phone,
          email: values.email,
          branch: values.branch,
          nationalId: values.nationalId,
        }
      );
      if (response.status === 200) {
        return response.data;
      }
    }
    return false;
  } catch (error) {
    throw error.response.data;
  }
}

export async function getEventByStatus(qs) {
  try {
    const { data } = await mainAPIClient.get(`/event?status=${qs}`);
    return data;
  } catch (error) {
    console.log(error);
  }
}

export async function getEventByEventId(id) {
  try {
    const { data } = await mainAPIClient.get(`/event/eventId/${id}`);
    return data;
  } catch (error) {
    console.log(error);
  }
}
