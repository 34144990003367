import React from "react";
// import ScheduleTab from "../components/Event/ScheduleTab";
import EventDetails from "../components/Event/EventDetails";
import Footer from "../components/Footer/Footer";
import FooterData from "../components/Footer/FooterData";
import { trackEvent, PageEventName } from "../services/analytics";

const Event = () => {
  React.useEffect(() => {
    trackEvent(PageEventName.Event);
  }, []);

  return (
    <div className="body_wrapper">
      <EventDetails />
      {/* <ScheduleTab /> */}
      <Footer FooterData={FooterData} />
    </div>
  );
};

export default Event;
