import React from "react";

const ChatClients = () => {
  return (
    <div>
      <div className="chat_get_started_area" />
      <div className="container">
        <div className="chat_get_started_content text-center">
          <h2 className="wow fadeInUp" data-wow-delay="0.2s">
            Jom bersama menjana perubahan!
          </h2>
          <br />
          <a
            href="https://linktr.ee/ProfileGEMA"
            target="__blank"
            className="chat_btn btn_hover"
          >
            Ketahui Lebih Lanjut
          </a>
        </div>
      </div>
    </div>
  );
};

export default ChatClients;
