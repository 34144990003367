export const branch = [
  { title: "Perlis", value: 0 },
  { title: "Kedah", value: 1 },
  { title: "Pulau Pinang", value: 2 },
  { title: "Perak", value: 3 },
  { title: "Selangor", value: 4 },
  { title: "Kuala Lumpur", value: 5 },
  { title: "Negeri Sembilan", value: 6 },
  { title: "Melaka", value: 7 },
  { title: "Johor", value: 8 },
  { title: "Pahang", value: 9 },
  { title: "Terengganu", value: 10 },
  { title: "Kelantan", value: 11 },
];

export const states = [
  "Perlis",
  "Kedah",
  "Pulau Pinang",
  "Perak",
  "Selangor",
  "Kuala Lumpur",
  "Negeri Sembilan",
  "Melaka",
  "Johor",
  "Pahang",
  "Terengganu",
  "Kelantan",
  "Sabah",
  "Sarawak",
  "Labuan",
  "Putrajaya",
];

export const interests = [
  "Media & komunikasi",
  "Kesenian & Kebudayaan",
  "Sukan & Rekreasi",
  "Khidmat masyarakat & Pembangunan",
  "Pembangunan keluarga",
  "Ekonomi & Kewangan",
  "Pendidikan",
  "Siasah & Keadilan sosial",
  "Perundangan",
  "Perniagaan",
];

export const genders = ["LELAKI", "PEREMPUAN"];

export const races = [
  "MELAYU",
  "CINA",
  "INDIA",
  "IBAN",
  "BUGIS",
  "KADAZANDUSUN",
  "BAJAU",
  "MURUT",
  "LAIN-LAIN",
];

export const religions = [
  "ISLAM",
  "BUDDHA",
  "KRISTIAN",
  "HINDU",
  "SIKH",
  "LAIN-LAIN",
];

export const education = [
  "SPM",
  "STPM",
  "DIPLOMA",
  "SARJANA MUDA",
  "SARJANA",
  "DOKTOR FALSAFAH",
  "LAIN-LAIN",
];
