import React, { useRef } from "react";
import { Document, pdfjs, Page as ReactPdfPage } from "react-pdf";
import HTMLFlipBook from "react-pageflip";
import bg from "../img/hosting/map.png";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

const Page = React.forwardRef(({ pageNumber, width }, ref) => {
  return (
    <div ref={ref}>
      <ReactPdfPage pageNumber={pageNumber} width={width} />
    </div>
  );
});

const PdfViewer = ({ file }) => {
  const { height, link, width } = file;
  const book = useRef();
  // const [pageWidth, _setPageWidth] = useState(2);
  const pages = [];

  function onDocumentLoadSuccess({ numPages }) {
    for (var i = 1; i <= numPages; i++) {
      pages.push(<Page key={i} pageNumber={i} width={width} />);
    }
  }

  return (
    <section
      className="multilink_area"
      style={{ backgroundImage: `url(${bg})` }}
    >
      <a href="/">
        <img src={require("../img/logo.png")} alt="logo" />
      </a>
      <div className="row_container">
        <div>
          <img
            width={50}
            src={require("../img/pdf/left-arrow.png")}
            alt="back"
            onClick={() => book.current.pageFlip().flipPrev()}
          />
        </div>
        <div
          style={{
            width: width * 2,
            backgroundColor: "rgba(236,236,236,0.6)",
          }}
        >
          <Document
            file={{
              url: link,
            }}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            <HTMLFlipBook
              showCover={true}
              mobileScrollSupport={false}
              ref={book}
              width={width}
              height={height}
            >
              {pages}
            </HTMLFlipBook>
          </Document>
        </div>
        <div>
          <img
            width={50}
            src={require("../img/pdf/right-arrow.png")}
            alt="back"
            onClick={() => book.current.pageFlip().flipNext()}
          />
        </div>
      </div>
    </section>
  );
};

export default PdfViewer;
