import React, { Component } from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import ScrollToTopRoute from "./ScrollToTopRoute";
import { initialize } from "./services/analytics";
/*------ Pages-----*/
import { Home } from "./Pages/Home";
import Registration from "./Pages/Registration";
import Associate from "./Pages/Associate";
import Renew from "./Pages/Renew";
import Verify from "./Pages/Verify";
import Event from "./Pages/Event";
import NotFound from "./Pages/404";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import TermsOfService from "./Pages/TermsOfService";
import Redirect from "./Pages/Redirect";
import MultiLink from "./Pages/MultiLink";
import Pdf from "./Pages/Pdf";
import Thanks from "./Pages/Thanks";
import Donation from "./Pages/Donation";
import HiTea10Tahun from "./Pages/HiTea10Tahun";

class App extends Component {
  componentDidMount() {
    this.props.hideLoader();
    if (process.env.NODE_ENV === "production") {
      initialize();
    }
  }
  render() {
    return (
      <Router>
        <Switch>
          <ScrollToTopRoute exact={true} path={"/"} component={Home} />
          <ScrollToTopRoute path={"/associate"} component={Associate} />
          <ScrollToTopRoute path={"/event"} component={Event} />
          <ScrollToTopRoute path={"/register"} component={Registration} />
          <ScrollToTopRoute path={"/verify"} component={Verify} />
          <ScrollToTopRoute path={"/renew"} component={Renew} />
          <ScrollToTopRoute path={"/thanks"} component={Thanks} />
          <ScrollToTopRoute path={"/donation"} component={Donation} />
          <ScrollToTopRoute
            path={"/HiTea10TahunGEMA"}
            component={HiTea10Tahun}
          />
          <ScrollToTopRoute
            path={"/privacy-policy"}
            component={PrivacyPolicy}
          />
          <ScrollToTopRoute
            path={"/terms-of-service"}
            component={TermsOfService}
          />
          <ScrollToTopRoute exact={true} path="/r/:id" component={Redirect} />
          <ScrollToTopRoute
            exact={true}
            path="/links/:id"
            component={MultiLink}
          />
          <ScrollToTopRoute exact={true} path="/pdf/:id" component={Pdf} />
          <ScrollToTopRoute component={NotFound} />
        </Switch>
      </Router>
    );
  }
}

export default App;
