import React, { Component } from "react";
import Reveal from "react-reveal/Reveal";

export default class Admin extends Component {
  render() {
    return (
      <section className="payment_features_area">
        <div className="bg_shape shape_one"></div>
        <div className="bg_shape shape_two"></div>
        <div className="bg_shape shape_three"></div>
        <div className="container">
          <div className="row featured_item">
            <div className="col-lg-6">
              <Reveal effect="fadeInLeft">
                <div className="payment_featured_img">
                  <img
                    src={require("../../../img/home9/featured_img.png")}
                    alt=""
                  />
                </div>
              </Reveal>
            </div>
            <div className="col-lg-6 d-flex align-items-center">
              <Reveal effect="fadeInRight" duration={800}>
                <div className="payment_features_content pl_70">
                  <div className="icon">
                    <img
                      className="img_shape"
                      src={require("../../../img/home9/icon_shape.png")}
                      alt=""
                    />
                    <img
                      className="icon_img"
                      src={require("../../../img/home9/icon1.png")}
                      alt=""
                    />
                  </div>
                  <h2>
                    Sistem Keahlian Digital yang <br /> Mudah & Cepat
                  </h2>
                  <p>
                    Memperkenal sistem Sigma-GEMA yang menjadi platform untuk
                    pengurusan ahli gema
                  </p>
                  <a
                    href="https://admin.sigmagema.com/"
                    className="btn_hover agency_banner_btn pay_btn pay_btn_two mb-30"
                  >
                    admin login
                  </a>
                </div>
              </Reveal>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
