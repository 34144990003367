import React, { useEffect } from "react";
import moment from "moment";
import Reveal from "react-reveal/Reveal/";
import { Formik } from "formik";
import { TextField, CircularProgress, Paper } from "@material-ui/core";
import Footer from "../components/Footer/Footer";
import FooterData from "../components/Footer/FooterData";
import { checkMembership, createNewPayment, updateBranch } from "../api/member";
import Autocomplete from "@material-ui/lab/Autocomplete";
import qs from "query-string";
import {
  trackEvent,
  PageEventName,
  ButtonEventName,
  ActionEventName,
} from "../services/analytics";
import { useState } from "react";
import { branch } from "../utils/Constant";

let nationalIdMember = "";

const DesignBanner = () => {
  const [membershipInfo, setMembershipInfo] = React.useState({
    id: null,
    fail: false,
  });
  const [isNewApplicationSuccess, setIsNewApplicationSuccess] = React.useState(
    false
  );
  const [editMode, setEditMode] = useState(false);
  const [newBranch, setNewBranch] = useState(0);
  const [updated, setUpdated] = useState("");

  useEffect(() => {
    trackEvent(PageEventName.Verification);
    const query = qs.parse(window.location.search);
    if (query.success) {
      trackEvent(ActionEventName.REGISTER_COMPLETED);
      setIsNewApplicationSuccess(true);
    }
  }, []);

  return (
    <React.Fragment>
      <section className="agency_banner_area">
        <img
          className="banner_shap"
          src={require("../img/home4/banner_bg.png")}
          alt=""
        />
        <div className="home_bubble">
          <div className="bubble b_one"></div>
          <div className="bubble b_two"></div>
          <div className="bubble b_three"></div>
          <div className="bubble b_four"></div>
          <div className="bubble b_five"></div>
          <div className="bubble b_six"></div>
          <div className="triangle b_seven" data-parallax='{"x": 20, "y": 150}'>
            <img src={require("../img/seo/triangle_one.png")} alt="" />
          </div>
          <div
            className="triangle b_eight"
            data-parallax='{"x": 120, "y": -10}'
          >
            <img src={require("../img/seo/triangle_two.png")} alt="" />
          </div>
          <div className="triangle b_nine">
            <img src={require("../img/seo/triangle_three.png")} alt="" />
          </div>
        </div>
        <div className="container custom_container">
          <div className="row">
            {isNewApplicationSuccess ? (
              <div className="col-md-12 text-center">
                <img
                  className="mh_50"
                  src={require("../img/utils/sucess.png")}
                  alt=""
                  width="250"
                />
                <h4>Terima Kasih! Permohonan anda telah diterima!</h4>
                <p>keputusan permohonan akan dimaklum melalui e-mel</p>
                <a href="/" className="seo_btn seo_btn_two btn_hover wow mv_10">
                  Kembali
                </a>
              </div>
            ) : (
              <>
                <div className="col-md-12 text-center">
                  <Reveal effect="fadeInUp" duration={500}>
                    <h1>Semakan Status Keahlian</h1>
                  </Reveal>
                  <Reveal effect="fadeInUp" duration={1000}>
                    <p className="wow fadeInUp" data-wow-delay="0.5s">
                      Sila masukkan nombor kad pengenalan
                    </p>
                  </Reveal>
                </div>
                <div className="col-md-12 text-center">
                  <Formik
                    initialValues={{ icNumber: "" }}
                    validate={(values) => {
                      const errors = {};
                      if (!values.icNumber) {
                        errors.icNumber = "No kad Pengenalan diperlukan";
                      }
                      return errors;
                    }}
                    onSubmit={async (values, { setSubmitting }) => {
                      setSubmitting(true);
                      const membership = {
                        nationalId: values.icNumber,
                      };
                      nationalIdMember = values.icNumber;
                      const result = await checkMembership(membership);
                      setMembershipInfo(result);
                      setSubmitting(false);
                    }}
                  >
                    {({
                      values,
                      errors,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="col-md-12 text-center">
                          <TextField
                            label="No. Kad Pengenalan"
                            required
                            margin="normal"
                            name="icNumber"
                            variant="outlined"
                            placeholder="eg: 900101011111"
                            style={{ width: "275px" }}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.icNumber}
                          />
                          <br />
                          {errors.icNumber && (
                            <p style={{ color: "red" }}>{errors.icNumber}</p>
                          )}
                        </div>
                        <div className="col-md-12 text-center">
                          <div className="verify_button_container">
                            {!isSubmitting ? (
                              <button
                                type="submit"
                                className="seo_btn seo_btn_two btn_hover wow mv_10"
                              >
                                Semak
                              </button>
                            ) : (
                              <CircularProgress />
                            )}

                            <a
                              href="/"
                              className="seo_btn seo_btn_three btn_hover wow mv_10"
                            >
                              Kembali
                            </a>
                          </div>
                        </div>
                      </form>
                    )}
                  </Formik>
                  <div className="col-md-12 mt_15">
                    {membershipInfo.id &&
                      MembershipStatusResult(
                        membershipInfo,
                        editMode,
                        setEditMode,
                        newBranch,
                        setNewBranch,
                        updated,
                        setUpdated
                      )}
                    {membershipInfo.fail && (
                      <>
                        <img
                          className="mh_50"
                          src={require("../img/utils/fail.png")}
                          alt=""
                          width="250"
                        />
                        <p>Harap Maaf! Anda Belum berdaftar.</p>
                        <a
                          href="/register"
                          className="seo_btn seo_btn_one btn_hover wow mv_10"
                          onClick={() => trackEvent(ButtonEventName.Register)}
                        >
                          Daftar Sekarang
                        </a>
                      </>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="saas_home_img">
            {membershipInfo &&
            membershipInfo.attended &&
            membershipInfo.attended.length > 0 ? (
              <></>
            ) : (
              <Reveal effect="fadeInUp" duration={1400}>
                <img src={require("../img/seo/image.png")} alt="" />
              </Reveal>
            )}
          </div>
        </div>
      </section>
      <Footer FooterData={FooterData} />
    </React.Fragment>
  );
};
export default DesignBanner;

const repayment = async (nationalId) => {
  const url = await createNewPayment({ nationalId });
  if (url != null) {
    window.location.href = url;
  } else {
    alert("Something When Wrong");
  }
};

function MembershipStatusResult(
  membershipInfo,
  editMode,
  setEditMode,
  newBranch,
  setNewBranch,
  updated,
  setUpdated
) {
  switch (membershipInfo.status) {
    case "ACTIVE":
      return (
        <>
          <h4>Tahniah</h4>
          <p> Anda adalah sebahagian dari kami!</p>
          <div className="row">
            <div className="animation_verify col-sm-12 col-md-4 offset-md-1">
              <lottie-player
                src="https://assets6.lottiefiles.com/private_files/lf30_m61uftnn.json"
                background="transparent"
                speed="0.4"
                style={{ width: 600, height: 450 }}
                loop
                autoplay
              />
            </div>
            <div className="col-sm-12 col-md-4 offset-md-1">
              <Paper className="mh_50 row" elevation={3}>
                <div className="col-md-12">
                  {membershipInfo.gender === "LELAKI" ? (
                    <img
                      className="mh_50"
                      src={require("../img/icon/boy.png")}
                      alt="boy"
                      width="96"
                    />
                  ) : (
                    <img
                      className="mh_50"
                      src={require("../img/icon/girl.png")}
                      alt="girl"
                      width="96"
                    />
                  )}
                </div>
                <div className="col-md-12">
                  <p>
                    No Ahli:{" "}
                    <span>
                      <b>{membershipInfo.id}</b>
                    </span>
                  </p>
                  <p>
                    Nama:{" "}
                    <span>
                      <b>{membershipInfo.name}</b>
                    </span>
                  </p>
                  <div className="flex align-item-center">
                    {editMode ? (
                      <Autocomplete
                        options={branch}
                        getOptionLabel={(option) => option.title}
                        onChange={(event, newValue) => {
                          setNewBranch(newValue.value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Cawangan"
                            variant="outlined"
                          />
                        )}
                      />
                    ) : (
                      <p>
                        Cawangan:{" "}
                        <span>
                          <b>{membershipInfo.branch.replace(/_/g, " ")}</b>
                        </span>
                      </p>
                    )}
                  </div>
                  <p>
                    Tarikh Daftar:{" "}
                    <span>
                      <b>
                        {membershipInfo.start
                          ? membershipInfo.start.split("T")[0]
                          : "Not Available"}
                      </b>
                    </span>
                  </p>
                  <p>
                    Tarikh Tamat:{" "}
                    <span>
                      <b>
                        {membershipInfo.expiry
                          ? membershipInfo.expiry.split("T")[0]
                          : "Not Available"}
                      </b>
                    </span>
                  </p>
                </div>
                <div className="col-md-12 mt-3 mb-3">
                  {editMode ? (
                    <button
                      onClick={() => {
                        updateBranch(membershipInfo.id, newBranch);
                        setEditMode(!editMode);
                        setUpdated(
                          "Permohonan untuk tukar cawangan telah dihantar"
                        );
                      }}
                    >
                      Mohon Penukaran
                    </button>
                  ) : (
                    <button
                      onClick={() => {
                        setEditMode(!editMode);
                      }}
                    >
                      Tukar Cawangan
                    </button>
                  )}
                  <p className="mt-1" style={{ color: "green" }}>
                    {updated}
                  </p>
                </div>
              </Paper>
            </div>

            <div className="col-sm-12 col-md-10 offset-md-1">
              {membershipInfo.attended && membershipInfo.attended.length > 0 && (
                <Paper className="p_25" elevation={3}>
                  <h4 className="mb_15">Aktiviti Yang telah disertai:</h4>
                  {membershipInfo.attended.map((ev) => (
                    <>
                      <p className="text-left">
                        {moment.utc(ev.startDate).format("DD MMM YYYY")} -{" "}
                        <b>{ev.name}</b>
                      </p>
                    </>
                  ))}
                </Paper>
              )}
            </div>
          </div>
        </>
      );
    case "ALUMNI":
      return (
        <>
          <h4>Tahniah!</h4>
          <p> Anda sebahagian dari ahli Alumni kami!</p>
        </>
      );
    case "PAID":
      return (
        <>
          <h4>Harap Maaf!</h4>
          <p> Permohonan masih belum diluluskan!</p>
        </>
      );
    case "PENDING":
      return (
        <>
          <h4>Harap Maaf!</h4>
          <p> Pembayaran yuran keahlian masih belum diselesaikan</p>
          <button
            className="seo_btn seo_btn_one btn_hover wow mv_10"
            onClick={() => repayment(nationalIdMember)}
          >
            Bayar Sekarang
          </button>
        </>
      );
    case "REJECTED":
      return (
        <>
          <h4>Harap Maaf!</h4>
          <p> Permohonan anda telah ditolak!</p>
        </>
      );
    case "EXPIRED":
      return (
        <>
          <h4>Harap Maaf!</h4>
          <p> Keahlian anda telah tamat tempoh!</p>
          <a
            href="/renew"
            className="seo_btn seo_btn_one btn_hover wow mv_10"
            onClick={() => trackEvent(ButtonEventName.Renew)}
          >
            Perbaharui Sekarang
          </a>
        </>
      );
    default:
      return null;
  }
}
