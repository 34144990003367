import React, { Component } from "react";
import Reveal from "react-reveal/Reveal";

export default class Item extends Component {
  render() {
    var { rowClass, fimage, ftitle, descriptions, aClass } = this.props;
    return (
      <div className={`agency_featured_item ${rowClass} `}>
        <div className="col-lg-6">
          <Reveal effect="fadeInLeft">
            <div className="agency_featured_img text-right">
              <img src={require("../../../img/home4/" + fimage)} alt="" />
            </div>
          </Reveal>
        </div>
        <div className="col-lg-6">
          <div
            className={`agency_featured_content wow fadeInLeft ${aClass}`}
            data-wow-delay="0.6s"
          >
            <Reveal effect="fadeInRight">
              <div className="dot">
                <span className="dot1"></span>
                <span className="dot2"></span>
              </div>
              <h3>{ftitle}</h3>
              <p>{descriptions}</p>
              <a href="/associate">
                <div className="icon mt_30">
                  <i className="ti-arrow-right"></i>
                </div>
                <span style={{ color: "black", marginLeft: "5px" }}>
                  Daftar Sebagai Rakan GEMA
                </span>
              </a>
            </Reveal>
          </div>
        </div>
      </div>
    );
  }
}
