import React from "react";
import TrackingBanner from "../components/Banner/TrackingBanner";
import TrackingPrice from "../components/TrackingPrice";
import Footer from "../components/Footer/Footer";
import FooterData from "../components/Footer/FooterData";
import ErpFeatures from "../components/Features/ErpFeatures";
import ChatClients from "../components/ChatClients";
// import StartupFeatures from "../components/Features/StartupFeatures";
import SecurityCustomerLogo from "../components/SecurityCustomersLogo";
import SubscribeDonation from "../components/SubscribeDonation";

const Invest = () => {
  return (
    <div className="body_wrapper">
      <TrackingBanner />
      <SubscribeDonation />
      {/* <StartupFeatures /> */}
      <ErpFeatures />
      <SecurityCustomerLogo />
      <TrackingPrice />
      <ChatClients />
      <Footer FooterData={FooterData} />
    </div>
  );
};
export default Invest;
