import Amplitude from "amplitude-js";

export const PageEventName = {
  Associate: "ASSOCIATE PAGE RENDERED",
  Event: "EVENT PAGE RENDERED",
  Home: "HOME PAGE RENDERED",
  Registration: "MEMBER REGISTRATION PAGE RENDERED",
  Renewal: "MEMBER RENEWAL PAGE RENDERED",
  Verification: "VERIFICATION PAGE RENDERED",
  Multilink: "MULTILINK PAGE RENDERED",
};

export const ButtonEventName = {
  AssociateRegisterSubmission: "ASSOCIATE REGISTRATION BUTTON CLICKED",
  Register: "REGISTER BUTTON CLICKED",
  Renew: "RENEW BUTTON CLICKED",
  RenewSubmission: "SUBMIT MEMBER RENEWAL BUTTON CLICKED",
  RegisterSubmission: "SUBMIT MEMBER REGISTRATION BUTTON CLICKED",
};

export const ActionEventName = {
  ASSOCIATE_REGISTER_COMPLETED: "ASSOCIATE REGISTRATION COMPLETED",
  REGISTER_COMPLETED: "MEMBER REGISTRATION COMPLETED",
};

export function initialize() {
  Amplitude.getInstance().init(process.env.REACT_APP_ANALYTICS_API_KEY);
}

export function trackEvent(eventName, eventProperties) {
  Amplitude.getInstance().logEvent(eventName, eventProperties);
}
