import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Autocomplete from "@material-ui/lab/Autocomplete";
import DialogTitle from "@material-ui/core/DialogTitle";
import { registerOrAttendEvent } from "../../api/event";
import { Formik } from "formik";
import { phoneNumber } from "../../utils/number";
import { branch } from "../../utils/Constant";

export default function EventDialog({
  id,
  fee,
  title,
  open,
  setOpen,
  status, // Indicator for Registration (0) and Attend (1)
  memberDiscount,
}) {
  const [uri, setURI] = React.useState(null);
  const [success, setSucces] = useState(false);
  const [isMember, setIsMember] = useState(true);
  const [price, setPrice] = useState(Number(fee));

  React.useEffect(() => {
    if (isMember && memberDiscount) {
      setPrice(Number(fee) - (Number(memberDiscount) / 100) * Number(fee));
    } else {
      setPrice(Number(fee));
    }
  }, [isMember, memberDiscount, fee]);

  React.useEffect(() => {
    if (uri) {
      return (window.location.href = uri);
    }
  }, [uri]);

  React.useEffect(() => {
    setSucces(false);
    setIsMember(true);
  }, [open]);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          <b>
            {" "}
            {`${status === 0 ? "Pendaftaran " : "Daftar Masuk "}${title}`}{" "}
          </b>
        </DialogTitle>
        <Formik
          initialValues={{
            nationalId: "",
            fullname: "",
            phone: "",
            email: "",
            branch: 0,
          }}
          onSubmit={(values, { setSubmitting, setErrors }) => {
            const { nationalId, fullname, phone, email, branch } = values;
            const obj = isMember
              ? { nationalId }
              : {
                  fullname,
                  nationalId,
                  phone: phoneNumber(phone),
                  email,
                  branch,
                };
            return registerOrAttendEvent(id, obj, status, isMember)
              .then((res) => {
                setSubmitting(false);
                setSucces(true);
                if (res.url) {
                  setURI(res.url);
                }
                setErrors({
                  id: `No Keahlian: ${res.membershipId}`,
                  remarks: res.remarks || "Berjaya. Sila Kembali...",
                });
                setTimeout(() => setOpen(false), 8000);
              })
              .catch((err) => {
                setSubmitting(false);
                console.log(err);
                if (
                  err.message ===
                  "Cannot read property 'memberStatus' of undefined"
                ) {
                  setErrors({
                    nationalId:
                      "Anda bukan ahli. Sekiranya pernah berdaftar sila layari pautan borang dibawah atau hubungi pasukan kami",
                  });
                }
                if (
                  err.message === "Member not Registered" ||
                  err.message === "Associate not Registered"
                ) {
                  setErrors({
                    nationalId: "Anda tidak Mendaftar untuk aktiviti ini",
                  });
                }
                if (err.message === "Membership is not active") {
                  setErrors({
                    nationalId: "Keahlian anda tidak aktif. Sila perbaharui..",
                  });
                  setTimeout(() => (window.location.href = "/renew"), 1500);
                }
              });
          }}
        >
          {({
            values,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          }) => (
            <>
              {isMember ? (
                <DialogContent>
                  <DialogContentText>
                    Sila masukkan Nombor Kad pengenalan anda
                  </DialogContentText>
                  <TextField
                    name="nationalId"
                    label="No Kad Pengenalan"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.nationalId}
                  />
                  <p style={{ color: "red", marginTop: 2 }}>
                    {errors.nationalId}
                  </p>
                  {fee !== 0 && status !== 1 && (
                    <p>{`Pembayaran sebanyak RM${price} akan dikenakan ketika pendaftaran`}</p>
                  )}
                  {!errors.remarks && (
                    <Button
                      style={{ color: "#5e2ced" }}
                      onClick={() => setIsMember(false)}
                    >
                      Bukan Ahli GEMA? {status === 0 ? "Daftar" : "Hadir"}{" "}
                      sebagai peserta biasa
                    </Button>
                  )}

                  <p style={{ color: "Black" }}>{errors.id}</p>
                  <p style={{ color: "green" }}>{errors.remarks}</p>
                </DialogContent>
              ) : (
                <DialogContent>
                  <DialogContentText>
                    Sila masukkan semua butiran dibawah
                  </DialogContentText>
                  <div className="row">
                    {status === 0 && (
                      <div className="col-12">
                        <TextField
                          name="fullname"
                          label="Nama Penuh"
                          type="text"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.fullname}
                          fullWidth
                        />
                      </div>
                    )}

                    <div className="col-md-6 col-sm-12">
                      <TextField
                        name="nationalId"
                        label="No Kad Pengenalan"
                        type="number"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.nationalId}
                        fullWidth
                      />
                    </div>
                    {status === 0 && (
                      <div className="col-md-6 col-sm-12">
                        <Autocomplete
                          options={branch}
                          getOptionLabel={(option) => option.title}
                          onChange={(_e, val) =>
                            setFieldValue("branch", val ? val.value : undefined)
                          }
                          onBlur={handleBlur}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Negeri"
                              value={values.branch}
                              fullWidth
                            />
                          )}
                        />
                      </div>
                    )}
                    {status === 0 && (
                      <div className="col-md-6 col-sm-12">
                        <TextField
                          name="phone"
                          label="No Telefon Bimbit"
                          type="text"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.phone}
                          fullWidth
                        />
                      </div>
                    )}
                    {status === 0 && (
                      <div className="col-md-6 col-sm-12">
                        <TextField
                          name="email"
                          label="E-mel"
                          type="text"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.email}
                          fullWidth
                        />
                      </div>
                    )}
                  </div>
                  {status === 1 && (
                    <p style={{ color: "#a485fd" }}>
                      *Pastikan no kad pengenalan yang sama ketika mendaftar
                    </p>
                  )}

                  <p style={{ color: "red", marginTop: 2 }}>
                    {errors.nationalId}
                  </p>
                  {fee !== 0 && status !== 1 && (
                    <p className="mt-3">{`Pembayaran sebanyak RM${price} akan dikenakan ketika pendaftaran`}</p>
                  )}
                  <p style={{ color: "Black" }}>{errors.id}</p>
                  <p style={{ color: "green" }}>{errors.remarks}</p>
                </DialogContent>
              )}
              {!success ? (
                <DialogActions>
                  <Button
                    disabled={isSubmitting}
                    onClick={handleClose}
                    color="primary"
                  >
                    Batal
                  </Button>
                  <Button
                    disabled={isSubmitting}
                    onClick={handleSubmit}
                    color="primary"
                  >
                    {status === 0 ? "Daftar" : "Hadir"}
                  </Button>
                </DialogActions>
              ) : (
                <DialogActions>
                  <Button
                    disabled={isSubmitting}
                    onClick={handleClose}
                    color="primary"
                  >
                    Kembali
                  </Button>
                </DialogActions>
              )}
            </>
          )}
        </Formik>
      </Dialog>
    </div>
  );
}
