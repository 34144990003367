import React, { Component } from "react";
import ErpIconitem from "./ErpIconitem";
import Slider from "react-slick";

class ErpFeaturesitem extends Component {
  render() {
    let { rowClass } = this.props;
    const settings = {
      dots: true,
      infinite: true,
      speed: 2000,
      fade: true,
      arrows: false,
      autoplay: true,
      autoplaySpeed: 2000,
      slidesToShow: 1,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <div className={`row erp_item_features ${rowClass}`}>
        <div className="col-lg-6">
          <div className="erp_features_img_two">
            <Slider className="app_screenshot_slider" {...settings}>
              <div className="item">
                <div className="screenshot_img">
                  <img
                    src={require("../../img/passion-clusters/green-heroes/gh2.png")}
                    alt=""
                  />
                </div>
              </div>
              <div className="item">
                <div className="screenshot_img">
                  <img
                    src={require("../../img/passion-clusters/green-heroes/gh4.png")}
                    alt=""
                  />
                </div>
              </div>
              <div className="item">
                <div className="screenshot_img">
                  <img
                    src={require("../../img/passion-clusters/green-heroes/gh10.png")}
                    alt=""
                  />
                </div>
              </div>
              <div className="item">
                <div className="screenshot_img">
                  <img
                    src={require("../../img/passion-clusters/green-heroes/gh8.png")}
                    alt=""
                  />
                </div>
              </div>
              <div className="item">
                <div className="screenshot_img">
                  <img
                    src={require("../../img/passion-clusters/green-heroes/gh9.png")}
                    alt=""
                  />
                </div>
              </div>
              <div className="item">
                <div className="screenshot_img">
                  <img
                    src={require("../../img/passion-clusters/green-heroes/gh1.png")}
                    alt=""
                  />
                </div>
              </div>
            </Slider>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="erp_content_two">
            <div className="hosting_title erp_title">
              <h2>GREEN HEROES</h2>
              <p>
                Merekalah pencinta alam yang tidak pernah putus asa dalam misi
                menyelamatkan bumi. <br />
                Semoga usaha-usaha murni yang dilakukan oleh pasukan sukarelawan
                GREEN HEROES menjadi inspirasi dan meniup semangat masyarakat
                Malaysia untuk sama-sama menjaga kelestarian alam sekitar.
              </p>
            </div>
            <ErpIconitem
              iconName="icon_ribbon_alt"
              erpText="MySaveFood at Ramadan"
            />
            <ErpIconitem
              iconName="icon_ribbon_alt"
              erpText="Plogging GEMA Merdeka"
            />
            <ErpIconitem
              iconName="icon_ribbon_alt"
              erpText="Kempen MYPlogging"
            />
            <ErpIconitem
              iconName="icon_ribbon_alt"
              erpText="Green Heroes Terjah"
            />
          </div>
        </div>
      </div>
    );
  }
}
export default ErpFeaturesitem;
