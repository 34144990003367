import React from "react";
import { CircularProgress, Chip } from "@material-ui/core";
import EventDialog from "./EventDialog";
import { getEventByEventId } from "../../api/event";
import qs from "query-string";
import moment from "moment";
import ReactHtmlParser from "react-html-parser";

const EventDetails = () => {
  const [open, setOpen] = React.useState(false);
  const [event, setEvent] = React.useState({});
  const [isLoading, setLoading] = React.useState(true);
  const [success, setSuccess] = React.useState(false);

  React.useEffect(() => {
    const fetchData = async () => {
      const { id, success } = qs.parse(window.location.search);
      const result = await getEventByEventId(id || 1);
      setSuccess(success);
      setEvent(result);
      setLoading(false);
    };
    fetchData();
  }, []);

  return (
    <section>
      {event && event.id && event.eventStatus !== 2 ? (
        <>
          <div className="container">
            <EventDialog
              id={event.id}
              title={event.name}
              open={open}
              setOpen={setOpen}
              status={event.eventStatus}
              fee={Number(event.fee)}
              memberDiscount={Number(event.memberDiscount)}
            />
          </div>
          <div className="container">
            <div className="event_img align-items-right">
              <img
                width="1024"
                src={
                  event.eventStatus === 0
                    ? require("../../img/home-event/promo.png")
                    : require("../../img/home-event/crowd.png")
                }
                alt=""
              />
            </div>
            <div className="row event_promotion_info align-items-center">
              <div className="col-md-12">
                <div className="e_promo_text wow fadeInDown">
                  <span>
                    <Chip
                      label={`${event.eventStatus === 0 ? "Baru" : "Aktif"}`}
                      color="error"
                    />
                    <br />
                    <span className="date">
                      {`${moment
                        .utc(event.startDate)
                        .format("Do MMMM YYYY")} - ${moment
                        .utc(event.endDate)
                        .format("Do MMMM YYYY")}`}
                    </span>
                  </span>
                  <h3>{event.name}</h3>
                  {success ? (
                    <div>
                      <img
                        src={require("../../img/utils/sucess.png")}
                        width="150"
                        alt=""
                      />
                      <p>Pendaftaran Berjaya</p>
                    </div>
                  ) : (
                    <button
                      onClick={() => setOpen(true)}
                      className={`event_btn btn_hover ${
                        event.eventStatus === 0
                          ? "event_btn_one"
                          : "event_btn_two"
                      }`}
                    >
                      {event.eventStatus === 0 ? "Daftar" : "Hadir"}
                    </button>
                  )}
                  <a href="/" className="agency_banner_btn_two">
                    Kembali<i className="ti-arrow-right"></i>
                  </a>
                </div>
              </div>
              <div className="col-md-12">
                <div className="row mt-5">
                  <div style={{ fontSize: 18 }}>
                    {ReactHtmlParser(event.description)}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="d-flex mt-3">
                      <img
                        src={require("../../img/home-event/marker.png")}
                        alt=""
                      />
                      <h6 className="mt-3">{event.venue}</h6>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="d-flex mt-3">
                      <img
                        src={require("../../img/home-event/timer.png")}
                        alt=""
                      />
                      <h6 className="mt-3">{`${moment
                        .utc(event.startDate)
                        .format("h:mm a")} - ${moment
                        .utc(event.endDate)
                        .format("h:mm a")}`}</h6>
                    </div>
                  </div>
                </div>
                <div className="row mt-5 mb-5">
                  <p>
                    <b>Yuran Penyertaan Program:</b>{" "}
                    {Number(event.fee) === 0 ? "Percuma" : `RM${event.fee} `}
                    {Number(event.memberDiscount) > 0 &&
                      `(Ahli: RM${(
                        Number(event.fee) -
                        (Number(event.memberDiscount) / 100) * Number(event.fee)
                      ).toPrecision(2)})`}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="container d-flex">
          {isLoading ? (
            <CircularProgress />
          ) : (
            <div>
              <img
                className="mh_50"
                src={require("../../img/utils/fail.png")}
                alt=""
                width="350"
              />
              <p>EVENT NOT EXIST OR HAS ENDED</p>
            </div>
          )}
        </div>
      )}
    </section>
  );
};
export default EventDetails;
