import React from "react";
import EventFeaturesItem from "./EventFeaturesItem";
import { getEventByStatus } from "../../api/event";

const EventFeatures = () => {
  const [events, setEvents] = React.useState([]);

  React.useEffect(() => {
    const fetchData = async () => {
      const result = await getEventByStatus(0);
      setEvents(result);
    };
    fetchData();
  }, []);

  return (
    <section className="event_features_area mb-4">
      {events && events.length > 0 && (
        <div className="container">
          <div className="hosting_title security_title text-center">
            <h2 className="wow fadeInUp" data-wow-delay="0.2s">
              <span>Aktiviti-aktiviti</span>yang sedang dianjurkan
            </h2>
          </div>
          <div className="row event_features_inner">
            {events.map((event) => (
              <EventFeaturesItem
                url={`/event?id=${event.eventId}`}
                image="leader.png"
                title={`${event.name}`}
                ptext={`${event.description}`}
                btn="Lihat"
              />
            ))}
          </div>
        </div>
      )}
    </section>
  );
};
export default EventFeatures;
