import React, { Component } from "react";
import Item from "./Item";

export default class Associate extends Component {
  render() {
    var { aClass } = this.props;
    return (
      <section className={`agency_featured_area bg_color ${aClass}`}>
        <div className="container">
          <h2
            className="f_size_30 f_600 t_color3 l_height40 text-center wow fadeInUp"
            data-wow-delay="0.3s"
          >
            Anda juga boleh hanya menjadi
            <br /> Rakan GEMA
          </h2>
          <div className="features_info">
            <Item
              rowClass="row flex-row"
              aClass="pr_70 pl_70"
              fimage="work3.png"
              ftitle="Memupuk Semangat kesukarelawanan"
              descriptions="sebagai rakyat malaysia kita hendaklah mempunyai jati diri serta hormat sesama kita dan saling bantu membantu agar kerja yang berat boleh menjadi ringan"
            />
          </div>
        </div>
      </section>
    );
  }
}
