import React, { useState } from "react";
import Footer from "../components/Footer/Footer";
import FooterData from "../components/Footer/FooterData";
import PdfViewer from "../components/PdfViewer";
import { getFileByShorten } from "../api/shorturl";

const Pdf = (props) => {
  const [file, setFile] = useState("");
  const path = props.match.params.id;

  React.useEffect(() => {
    const fetchData = async () => {
      const result = await getFileByShorten(path);
      if (result) {
        setFile(result);
      } else {
        window.location.href = "/404";
      }
    };
    fetchData();
  }, [path]);

  return (
    <div className="body_wrapper">
      <PdfViewer file={file} />
      <Footer FooterData={FooterData} />
    </div>
  );
};

export default Pdf;
