import React from "react";
import { Image } from "cloudinary-react";
import Background1 from "../../img/multilink/bg-1.png";
import Background2 from "../../img/multilink/bg-2.png";
import Background3 from "../../img/multilink/bg-3.png";
import Background4 from "../../img/multilink/bg-4.png";
import Background5 from "../../img/multilink/bg-5.png";
import Background6 from "../../img/multilink/bg-6.png";
import Background7 from "../../img/multilink/bg-7.png";
import Background8 from "../../img/multilink/bg-8.png";
import Background9 from "../../img/multilink/bg-9.png";
import Background10 from "../../img/multilink/bg-10.png";

const Tabs = ({ content }) => {
  let bg = Background1;
  if (content.background) {
    switch (content.background) {
      case "bg1":
        bg = Background1;
        break;
      case "bg2":
        bg = Background2;
        break;
      case "bg3":
        bg = Background3;
        break;
      case "bg4":
        bg = Background4;
        break;
      case "bg5":
        bg = Background5;
        break;
      case "bg6":
        bg = Background6;
        break;
      case "bg7":
        bg = Background7;
        break;
      case "bg8":
        bg = Background8;
        break;
      case "bg9":
        bg = Background9;
        break;
      case "bg10":
        bg = Background10;
        break;
      default:
        bg = content.background;
    }
  }

  return (
    <section
      className="multilink_area"
      style={{ backgroundImage: `url(${bg})`, minHeight: 700 }}
    >
      <a href="/">
        <img src={require("../../img/logo.png")} alt="logo" />
      </a>
      <div className="container text-center">
        <Image
          style={{ borderRadius: "50%" }}
          className="mh_30"
          cloudName="denr13kdi"
          publicId={content.icon}
          width={75}
        />
        <div className="security_title">
          <h2 className="t_color">{content.title}</h2>
        </div>
        <p className="t_color2">{content.desc}</p>
        <div className="event_schedule_inner">
          <div className="event_tab_content">
            <div>
              {content.links &&
                content.links.map((link) => {
                  if (link.isActive) {
                    return (
                      <a
                        key={link.title}
                        href={link.value}
                        className="media"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textAlign: "center" }}
                      >
                        <h3>{link.title}</h3>
                      </a>
                    );
                  }
                  return null;
                })}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Tabs;
