import React from "react";
import Sectitle from "../../components/Title/Sectitle";

const Video = () => {
  return (
    <section className="video_area bg_color sec_pad">
      <div className="container">
        <Sectitle
          Title="Pemuda Gema adalah suara anak muda"
          TitleP="Pemuda Gema terdiri daripada individu yang komited dalam membawa perubahan positif kepada masyarakat"
          tClass="t_color3"
          sClass="sec_title text-center mb_70"
        />
        <div className="video_content">
          <div className="video_info">
            <div className="ovarlay_color"></div>
            <iframe
              title="youtube"
              width="560"
              height="315"
              src="https://www.youtube.com/embed/Kf1x5-jwnfg"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
            <h2>GEMA</h2>
          </div>
          <img
            className="video_leaf"
            src={require("../../img/new/leaf.png")}
            alt=""
          />
          <img className="cup" src={require("../../img/new/cup.png")} alt="" />
        </div>
      </div>
    </section>
  );
};

export default Video;
